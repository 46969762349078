import React from 'react';
import Card from '../components/Card';
import InputRangeSlider from '../components/Slider';
import './Mint1.css'
import { useState, useEffect } from 'react';
import MintButton from '../components/MintButton';
import ConnectButton from '../components/ConnectButton';
import { useParams } from 'react-router-dom';
import { checkSupply,checkStatus,getCurrentWalletConnected } from "../utils/interact";

function Mint1() {
  
  const[free,setFree] = useState(false);
  const [currentValue,setCurrentValue] = useState(0);



  useEffect(() => {
   
  },[])
  
  const [total,setTotal] = useState();
  const [valid,setValid] = useState(true);
  const [totalDisplay,setTotalDisplay] = useState(false);
  
  const { id } = useParams();
 
  

  console.log(total);
  
  console.log(id);


  
  const maxValue = 5555;
  
  const [connect,setConnect] = useState(true);
  const [value,setValue]= useState(1);

  if(!connect){
    checkSupply().then((data) => {
      if(data.success!=false){
        setTotalDisplay(true)
        setTotal(data);
        
      }
    })
  }

  return (
    <>
    <div className='test'>
      <div className='mobalert'>
      <p>Note: Use metamask browser on mobile device. </p>
      </div>
        <div className='mintcard'>
        <Card value={value}/>
        {/* {free ? <p className='text-white mt-5'>{`1 Free mint+${parseFloat(0.004*(value-1)).toFixed(3)} eth`}</p> : <p className='text-white mt-5'>Mint price: {`${parseFloat(0.004*value).toFixed(3)} eth`}</p>} */}
        {connect ? <div/> : <div> {<p className='text-white text-lg'>Price: {`${parseFloat(0.0001*value).toFixed(3)} eth`}</p>}</div>}
        <div className='flex items-center '>
        
        <progress 
    className='progressBar'
    value={total} 
    max={5555}
    >
      {currentValue}%
    </progress>
    {/* <progress className='progressBar' value={0.5} /> */}
    {totalDisplay && <p className='text-white ml-2 mt-1'>{`[${total}/5555]`}</p>}
        </div>
        <InputRangeSlider value={value} setValue={setValue}/>
        {connect ? <ConnectButton setConnect={setConnect} /> :<MintButton value={value} setValue={setValue} free={free}/>}
        </div>
    </div> </>
  )
}

export default Mint1