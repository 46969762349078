import React from 'react'
import './Card.css'
import ImageSlideshow from './ImageSlideshow';
import { useState, useEffect } from 'react';

import reveal from "../assets/banner/Prereveal.gif"







function Card({value}) {


  return (
    <div >
        <div className='card mb-8'>
        {/* <ImageSlideshow images={images} /> */}
        <img src={reveal} alt="" />
        </div>
    </div>
  )
}

export default Card