import React from "react";
import "./MintButton.css";
import { useEffect,useState } from "react";
import { connectWallet,getCurrentWalletConnected,mintNFT } from "../utils/interact";
function MintButton({value,setValue,free}) {
  
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [inbal, setInbal] = useState(true);
  useEffect(() => { 
    const {address, status} =   getCurrentWalletConnected();
    setWallet(address)
    setStatus(status); 
    
  }, []);

  const connectWalletPressed = async () => { 
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  return (
    <div className="buttons">
        <p>{status}</p>
        <p>{walletAddress}</p>
      <button onClick={() => {
        console.log("Clicked");
        console.log(value);
        
         mintNFT(value).then((msg) => {
           console.log(msg);
           console.log(msg.success);
           if(!msg.success && msg.status != '😥 Something went wrong: MetaMask Tx Signature: User denied transaction signature.'){
            setInbal(false);
            setTimeout(() => {
              // Set inbal back to true after 5 seconds
              setInbal(true);
          }, 3000);
           }
         })
      }} className="bg-[#D85F5D] text-lg hover:bg-[#26eeda] text-white font-bold py-4 px-14 rounded-full border-4">
       {inbal ? 'MINT' : 'INSUFFICIENT BAL'}
        
      </button>
      <br />

      
    </div>
  );
}

export default MintButton;
