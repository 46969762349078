import React from "react";
import Grid from "../components/Grid";
import "./About.css";
function About() {
  return (
    <div className="abt">
      <div class="titleabout text-contain oxanium-font">
        <div className="txt">
          <br></br>
          <br></br>
          <h1 className="text-white">ABOUT</h1>
          <br></br>
          <h6 className="">
          Welcome to the Base Ronins NFT Collection, where tradition meets innovation on the Base blockchain. Our digital masterpiece seamlessly merges the elegance of samurai culture with the charm of feline companions, offering collectors an immersive experience in the world of Baseland.
          <br></br>
          <br></br>
          With a total supply of 5555 NFTs, the Base Ronins Collection is both exclusive and accessible. But it doesn't end there. We're committed to fostering a vibrant community of collectors, which is why we have exciting plans in store, including airdrops for our valued holders.
          <br></br>
          <br></br>

          <br></br>
          Join us on this journey as we redefine the possibilities of digital art and collectibles on the Base blockchain. Become a part of the Base Ronins community today and embark on an adventure where tradition, creativity, and technology converge.
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </h6>

        </div>

        
      </div>

      
    </div>
  );
}

export default About;
