import React from "react";
import Slider from "../components/Slider";
import "./Mint.css";
import { useState } from "react";
import banner from  '../assets/banner/gifog1.PNG'
import frame from '../assets/background/punks.png'

import { NavLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";
function Mint() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  return (
    <div class="containe1">
      
        <img src={frame} alt="" />
      <div class="right-col  flex-col">
        <h1>BASE RONINS</h1>
        <h4 className=" pb-4 last oxanium-font">
        <br></br>
        Welcome to the Base Ronins NFT Collection, a digital masterpiece on the Base blockchain that seamlessly merges the elegance of samurai culture with the charm of feline companions. This unique collection of Non-Fungible Tokens (NFTs) represents a harmonious blend of Japanese aesthetic and playful creativity, offering collectors an immersive experience in the world Baseland.
<br></br>
<br></br>
The NFT collection features meticulously designed samurai cats in various poses, outfits, and settings. Each NFT is a digital work of art that captures the grace of samurais alongside the adorable and mischievous nature of cats. As a collector, you are not just acquiring NFTs; you are entering a world where tradition meets innovation on the Base blockchain. For more info <a href="https://baseronins.xyz/#/about" className="text-[#006ce8]">Click Here</a>
<br></br>

        </h4>
        {/* <NavLink
              exact
              to={"/about"}
              className="bg-white p-7 px-20 text-xl shadow-md shadow-black text-[#191919]  rounded-md flex space-x-2 w-fit px-8 border-[#191919] border-2 p-3 mt-6 h-10 font-normal text-sm leading-3 text-white bg-[#191919] focus:outline-none focus:bg-[#1DA1F2] hover:bg-[#1DA1F2] duration-150 justify-center items-center"
              activeClassName=""
            >
              MINT
            </NavLink> */}
            <button onClick={() => {
          navigate("/mint");
      }} class=" bg-[#1dac9e] mb-8 hover:bg-[#26eeda] text-white font-bold py-4 px-14 rounded-full border-4">
    MINT 
    
</button>
    
      </div>
      
    </div>
  );
}

export default Mint;
