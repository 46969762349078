import React from 'react'
import opensea from "../assets/logo/opensea.png";
import etherscan from "../assets/logo/etherscan.png";
import twitter from "../assets/logo/twitter.png";
import discord from "../assets/logo/discord.png";

function Socials() {
  return (
    <div className="flex justify-evenly items-center w-28  ">
    <a target="_blank" href="https://opensea.io/collection/base-ronins-1">
      <img src={opensea} alt="" />
    </a>
    <a target="_blank" className="mx-4" href="https://twitter.com/baseroninsnft">
      <img src={twitter} alt="" />
    </a>
    <a target="_blank" className='w-48' href="https://basescan.org/address/0x1220c1ac310e9acd3ef630215f9b08ba6b109b54">
      <img src={etherscan} alt="" />
    </a>
  </div>
  )
}

export default Socials