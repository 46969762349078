import Home from "./pages/Home";

import {
  HashRouter  as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Mint from "./pages/Mint";
import About from "./pages/About";
import './assets/punkcss/cyberpunk-css-main/cyberpunk.css'

import MintOg from "./pages/Mint1"





function App() {
  return (
    <Router>
      <Routes>
        <Route path={""} element={<Home />}>
          <Route exact path={"/"} element={<Navigate replace to="/home" />} />
          <Route path={"/home"} element={<Mint/>} />
          <Route path={"/about"} element={<About/>} />
          <Route path={"/mint/:id?"} element={<MintOg/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
