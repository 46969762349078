import React from "react";

import Menu1 from './Menu1';

import './Header.css'
import Socials from "./Socials";

function Header() {
  
  return (
    <div className="  ">
      <div className="headerr">
        
        <Menu1/>
        
      </div>

    </div>
  );
}

export default Header;
