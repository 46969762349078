import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Socials from "./Socials";
import "./MobileMenu.css";
const MobileMenu = ({show,setshow}) => {



  // Function to close the menu
  const closeMenu = () => {
    setshow(false);
  };

  // Event listener for scroll events
  const handleScroll = () => {
    // Check if the menu is open and close it if scrolled
    if (show) {
      closeMenu();
    }
  };

  useEffect(() => {
    // Attach scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [show]);

  return (
    <div className={`${show ? 'block' : 'hidden'} mob sm:hidden mt-4 mx-auto `}>
      <div className="flex flex-row items-center justify-center space-x-6">
        <Socials />
      </div>
      <div className="flex flex-col gap-4 mt-4 w-80 mx-auto">
        <NavLink
          exact
          to="/home"
          className="link rounded-md flex space-x-2 w-full h-10 font-bold text-lg leading-3 focus:outline-none focus:bg-[#1DA1F2] hover:bg-[#1DA1F2] duration-150 justify-center items-center"
          activeClassName="active"
        >
          Home
        </NavLink>
        <NavLink
          exact
          to="/mint"
          className="link rounded-md flex space-x-2 w-full h-10 font-bold text-lg leading-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1fa1f1] focus:bg-[#1DA1F2] hover:bg-[#1DA1F2] duration-150 justify-center items-center"
          activeClassName="active"
        >
          Mint
        </NavLink>
       
        {/* Other NavLink components */}
        <NavLink
          exact
          to="/about"
          className="link rounded-md flex space-x-2 w-full h-10 font-bold text-lg leading-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1fa1f1] focus:bg-[#1DA1F2] hover:bg-[#1DA1F2] duration-150 justify-center items-center"
          activeClassName="active"
        >
          About
        </NavLink>
      </div>
    </div>
  );
};

export default MobileMenu;
