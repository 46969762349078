import React, { useState } from "react";
import './Slider.css'

const InputRangeSlider = ({value,setValue}) => {


const handleChange = (e) => {
setValue(e.target.value);
};

return (
<div>
<span id="rangeValue">{value}</span>
<input
     className="range"
     type="range"
     value={value}
     min="1"
     max="20"
     onChange={handleChange}
     onMouseMove={handleChange}
   />
</div>
);
};

export default InputRangeSlider;