import React, { Fragment,useState,createContext  } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import "./Home.css";

function Home() {
  const [connect,setConnect] = useState(true);
  console.log(connect);
  return (
    <Fragment>
      <div className="h-screen  ogBG oxanium-font  ">
        <div className="HomeBg  w-full "> 
        <Header />
        <div className="w-screen pt-14 pb-16 flex justify-center items-center">
          <Outlet connect={connect} setConnect={setConnect}/>
        </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Home